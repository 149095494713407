import { useCallback, useEffect, useState } from 'react';
import { ModelViewerElement } from '@google/model-viewer';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { FileUrl, Units } from '../../../../../../../types';
import { ModelViewerConfig } from '../../../../../../../helpers/ModelViewer/ModelViewer.types';

import { useLocalStorageProxyState } from '../../../../../../../common/hooks/useLocalStorageProxyState';
import { modelViewerLocalStorageKey } from '../../../../../../../helpers/ModelViewer/modelViewerConstants';

interface ModelViewerModalButtonOptions {
  model?: {
    file: FileUrl;
    name: string;
  };
  initialModelIndex?: number;
  models?: {
    file: FileUrl;
    name: string;
  }[];
}

function useModelViewerModalButton({
  model,
  initialModelIndex,
  models
}: ModelViewerModalButtonOptions) {
  const [activeItemIndex, setActiveItem] = useState(initialModelIndex || 0);
  const [withDimensions, setWithDimensions] = useState(false);
  const [withRuler, setWithRuler] = useState(false);
  const [modelViewerConfig, setModelViewerConfig] =
    useLocalStorageProxyState<ModelViewerConfig>(modelViewerLocalStorageKey, {
      units: Units.CM
    });
  const [modelViewerEl, setModelViewerEl] = useState<ModelViewerElement | null>(
    null
  );
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [withAvailableAnimations, setWithAvailableAnimations] =
    useState<boolean>(false);

  const handlePrevItem = useCallback(() => {
    setActiveItem((prevActiveSlide) =>
      prevActiveSlide > 0 ? prevActiveSlide - 1 : models?.length - 1
    );
  }, [models?.length]);

  const handleNextItem = useCallback(() => {
    setActiveItem((prevActiveSlide) =>
      prevActiveSlide < models?.length - 1 ? prevActiveSlide + 1 : 0
    );
  }, [models?.length]);

  const activeItem = isArray(models) ? models[activeItemIndex] : model;

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.target === modelViewerEl) return;

      switch (e.key) {
        case 'ArrowLeft':
          handlePrevItem();
          break;

        case 'ArrowRight':
          handleNextItem();
          break;
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [handleNextItem, handlePrevItem, modelViewerEl]);

  const toggleWithDimensions = useCallback(
    () => setWithDimensions((v) => !v),
    []
  );

  const toggleWithRuler = useCallback(() => setWithRuler((v) => !v), []);

  const toggleUnits = useCallback(
    () =>
      setModelViewerConfig({
        ...modelViewerConfig,
        units: modelViewerConfig.units === Units.CM ? Units.IN : Units.CM
      }),
    [modelViewerConfig, setModelViewerConfig]
  );

  const toggleAnimationState = useCallback(() => {
    isPaused ? modelViewerEl?.play() : modelViewerEl?.pause();
  }, [isPaused, modelViewerEl]);

  useEffect(() => {
    const onAnimationStateChanged = () => {
      setIsPaused(modelViewerEl?.paused ?? true);
    };

    modelViewerEl?.addEventListener('play', onAnimationStateChanged);
    modelViewerEl?.addEventListener('pause', onAnimationStateChanged);
    onAnimationStateChanged();

    return () => {
      modelViewerEl?.removeEventListener('play', onAnimationStateChanged);
      modelViewerEl?.removeEventListener('pause', onAnimationStateChanged);
    };
  }, [modelViewerEl]);

  useEffect(() => {
    const onLoad = () => {
      const hasAnimations = !isEmpty(modelViewerEl?.availableAnimations || []);
      setWithAvailableAnimations(hasAnimations);
    };

    modelViewerEl?.addEventListener('load', onLoad);

    return () => {
      modelViewerEl?.removeEventListener('load', onLoad);
    };
  }, [modelViewerEl]);

  return {
    multiplyItems: size(models) > 1,
    modelViewerEl,
    setModelViewerEl,
    activeItem,
    handlePrevItem,
    handleNextItem,
    activeItemIndex,
    withDimensions,
    toggleWithDimensions,
    withRuler,
    toggleWithRuler,
    units: modelViewerConfig.units,
    toggleUnits,
    toggleAnimationState,
    withAvailableAnimations,
    isPaused
  };
}

export default useModelViewerModalButton;
